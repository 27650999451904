import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import { encode } from "querystring";
import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import ProjectsByCategory from "../components/Projects";
import SEO from "../components/Seo";

import av1 from "../images/projects/avmusic.png";
import baba from "../images/projects/baba.png";
import besttaste from "../images/projects/besttaste.png";
import bilguunzul from "../images/projects/bilguunzul.png";
import esan from "../images/projects/esan.png";
import irl from "../images/projects/irl.png";
import pmw from "../images/projects/pmw.png";
import sa from "../images/projects/sa.png";
import sl from "../images/projects/sl.png";
import urban from "../images/projects/urban-service.png";

import { slice } from "lodash";

interface IProps {}

const edu = [
  {
    url: esan,
  },
];

const teh = [
  {
    url: sl,
  },
];

const busad = [
  {
    url: irl,
  },
  {
    url: pmw,
  },
  {
    url: besttaste,
  },
];

const barilga = [
  {
    url: baba,
  },
  {
    url: urban,
  },
];

const urlag = [
  {
    url: av1,
  },
];
const companies = (props: IProps) => {
  const { formatMessage: fm } = useIntl();

  const [change, setHover] = useState(false);
  const Hover = () => {
    setHover(!change);
  };
  return (
    <>
      <SEO title={fm({ id: "menu-projects" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-end justify-center transform scale-100">
              <StaticImage
                src="../images/bg1.jpg"
                alt=""
                className="w-full object-left-bottom object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40"></div>
            <div className="container relative mx-auto px-4 h-screen flex items-center justify-center text-white">
              <p
                className="text-xl font-cool w-full text-center lg:w-2/3 lg:text-4xl tracking-wider leading-snug text-white"
                data-sal="slide-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {fm({ id: "projects-title" })}
              </p>
            </div>
          </div>
          <div className="w-full overflow-hidden relative bg-white">
            <div>
              <ProjectsByCategory title="Технологи" projects={teh} />
              <ProjectsByCategory title="Боловсрол" projects={edu} />
              <ProjectsByCategory title="Соёл урлаг" projects={urlag} />
              <ProjectsByCategory
                title="Барилга, Үл хөдлөх хөрөнгө"
                projects={barilga}
              />
              <ProjectsByCategory title="Бусад" projects={busad} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default companies;
