import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import { encode } from "querystring";
import React, { useState, useEffect } from "react";

interface IProps {
  title: string;
  projects: any[];
}

const ProjectsByCategory = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [change, setHover] = useState(false);
  const Hover = () => {
    setHover(!change);
  };
  return (
    <>
      <div>
        <div className="w-full overflow-hidden relative bg-white">
          <div>
            <div className="container mx-auto">
              <div
                className={`text-2xl ${change ? "text-main" : ""} my-10`}
                // data-sal="fade-up"
                // data-dal-delay="1000"
                // data-sal-duration={2000}
              >
                {props.title}
              </div>

              <div
                onMouseEnter={Hover}
                onMouseLeave={Hover}
                className="flex flex-wrap -mx-10 overflow-hidden border-t border-black border-opacity-20"
                data-sal="fade-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {props.projects?.map((pro) => (
                  <div className="px-10 w-1/2 lg:w-1/3 overflow-hidden flex justify-center items-center border-b border-black border-opacity-20 p-10">
                    <div className="transition-all filter grayscale hover:grayscale-0 w-1/2 mx-auto flex items-center justify-center">
                      <img src={pro.url} className="h-32" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsByCategory;
